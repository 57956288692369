import routes from "../constants/router"
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom"
import Login from "../pages/Login/Login"
import PageNotFound from "@/pages/statuses/404"
import { useSelector } from 'react-redux'
import React, { Suspense } from "react"
// console.log(routes)
import Fallback from '@/components/loading/index.jsx'
function Router() {
    const accessToken = 'sdas'
    // const accessToken = true
    function Loader({ path, children }) {
        const Component = path
        // console.log(children)
        return (
            <Suspense fallback={<Fallback />}>
                <Component>{children}</Component>
            </Suspense>
        )
    }
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Login} />
                {accessToken 
                ? <Route exact path="/"><Redirect from="/" to="/home/projects" /></Route> 
                : <Redirect to="/login" />
                }
                {accessToken ? routes.map((route, index) => (
                    <Route
                        exact={!route.children.length}
                        key={index}
                        path={route.path}
                    >
                        <route.component>
                            {
                                route.children.map((subRoute, index) => (
                                    <Route
                                        exact={!subRoute?.children?.length}
                                        key={`${index + 101}`}
                                        path={`${route.path}${subRoute.path}`}
                                    >
                                        {/* <subRoute.component>
                                            {
                                                subRoute.children.map((sub, inx) => (
                                                    <Route
                                                        exact={!sub?.children?.length}
                                                        key={`${inx + 144301}`}
                                                        path={`${route.path}${subRoute.path}${sub.path}`}
                                                        >
                                                        
                                                        <Loader path={sub.component} />
                                                    </Route>
                                                ))
                                            }
                                        </subRoute.component> */}
                                        <Loader path={subRoute.component} />
                                    </Route>
                                ))
                            }
                        </route.component>
                    </Route>
                )) :<Redirect to="/login" />
                }
                <Route path="/404" component={PageNotFound} />
                <Redirect from="*" to="/404" />
            </Switch>
        </BrowserRouter>
    );
}

export default Router;
