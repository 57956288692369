import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from './redux/store'
import React from 'react'
import 'nprogress/nprogress.css'
// import { store } from './redux/store'
// import { useTranslation } from "react-i18next";
// import Loader from './components/Loader'
import Router from './routes/routes'
// import Constructor from './containers/home'



function App() {
  // const isLoad = useSelector((state) => state.auth.isLoading)
  return (
    <PersistGate loading={null} persistor={persistor}>
      <div className='App'>
      <Router />
      </div>
    </PersistGate>
  )
}


export default App
