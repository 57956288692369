import { actionActionTypes } from '@/redux/actions/actionActions/actionActionTypes'

const initialActionState = {
  actions: [
    {
      id: '1',
      type: 'start',
      position: {
        x: 0,
        y: 0,
      },
    },
  ],
}

const actionReducer = (state = initialActionState, action) => {
  const { payload } = action
  switch (action.type) {
    case 'SET_ACTIONS':
      return {
        ...state,
        actions: payload,
      }
    case actionActionTypes.ADD_ACTION:
      return {
        ...state,
        actions: [...state.actions, payload],
      }
    case actionActionTypes.REMOVE_ACTION:
      return {
        ...state,
        actions: state.actions.filter((action) => action.id !== payload.id),
      }
    case actionActionTypes.UPDATE_ACTION:
      return {
        ...state,
        actions: state.actions.map((act) =>
          act.id === payload.id
            ? { ...act, ...payload}
            : act
        ),
      }

    case actionActionTypes.CLEAR_ACTION:
      return {
        ...state,
        actions: [],
      }
    default:
      return state
  }
}

export default actionReducer
