import { Avatar } from 'antd'
import { UserOutlined, LogoutOutlined } from '@ant-design/icons'
import { Menu, Dropdown } from 'antd'
import { useHistory } from 'react-router-dom'
import './styles/style.less'
import basic from '../constants/basic'
import { logout } from '../redux/actions'
import { useDispatch,useSelector } from 'react-redux'

function RightContent() {
  const user = useSelector(el => el?.auth?.user_info ? JSON.parse(el.auth.user_info) : {})

  const dispatch = useDispatch()
  const history = useHistory()
  const Logout = function () {
    dispatch({type: 'CLEAR_ON_SIGNOUT'})
    localStorage.removeItem('token')
    
    history.push('/login')
  }
  const menu = (
    <Menu>
      <Menu.Item onClick={() => history.push('/profile/info')}>
        <UserOutlined /> Профиль
      </Menu.Item>
      <Menu.Item
        onClick={() => {
            Logout()

        }}
      >
        <LogoutOutlined /> Выйти
      </Menu.Item>
    </Menu>
  )
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Dropdown overlay={menu} className='dropdown'>
        <div className='right_content'>
          <Avatar
            size={36}
            className='avatar'
            src={user?.image?.url}
            icon={<UserOutlined />}
          />
          <h1 className='title'>{user.name}</h1>
        </div>
      </Dropdown>
    </div>
  )
}

export default RightContent
