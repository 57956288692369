import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Alert } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import { isLoadingOverlay } from '@/redux/actions'
import Auth from '@/services/Auth'
import './login.less'
import { useHistory } from 'react-router-dom'

import logo from '@/assets/images/OxBox.png'
// console.log(Auth)
function Login() {
  const accessToken = useSelector(el => el.auth.accessToken)
  useEffect(() => {
    if (accessToken) {
      history.push('/')
    }
  })
  const dispatch = useDispatch()
  const history = useHistory()
  const [notfound, setNotFound] = useState(false)
  const [isloading, setIsloading] = useState(false)

  const onFinish = (values) => {
    console.log(values)
    setIsloading(true)
    Auth.login(values).then(res => {
      console.log(res)
      dispatch({ type: 'SET_AUTH_TOKENS', payload: { 
        user_id:res.token.user_id, 
        accessToken:res.token.access_token, 
        refreshToken: res.token.refresh_token, 
        user_role_id: res.token.user_role_id,
        user_info: res.user_info
      }})
      localStorage.setItem('token', res.token.access_token)
      history.push('/')
    }).catch(err => {
      console.log(err)
      setNotFound(true)
      dispatch(isLoadingOverlay(false))
    }).finally(() => setIsloading(false))
  }
  return (
    <div className='login' style={{ transaction: 'all 2s'}}>
      <div className='logo_content'>
        <img
          className='logo_image'
          alt={'Logo'}
          src={logo}
        />
      </div>
      <div className='login_content'>
        <h1>Войти в систему</h1>
        { notfound ? (<Alert style={{ marginBottom: '20px' }} message="Логин или пароль неверный !" type="error" />) : undefined }
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name='login'
            rules={[{ required: true, message: 'Пожалуйста, введите ваше имя пользователя!' }]}
          >
            <Input
              spellCheck="false"
              size="large"
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Введите логин'
            />
          </Form.Item>
          <Form.Item
            className="my-1"
            name='password'
            rules={[{ required: true, message: 'Пожалуйста, введите свой пароль!' }]}
          >
            <Input.Password
              spellCheck="false"
              size="large"
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='Введите пароль'
            />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              loading={isloading}
              htmlType='submit'
              className='login-form-button'
              size='large'
            >
              Войти в систему
            </Button>
            {/*Or <a href="">register now!</a>*/}
          </Form.Item>
        </Form>
        <p>All Rights Reserved. &copy; Udevs 2021</p>
      </div>
    </div>
  )
}
export default Login
