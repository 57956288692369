import not from '../../assets/images/404 Page Not Found _Monochromatic.png'
import './404.css'

function NotFound () {
    return (
        <div className='main'>
            <img src={not} alt="404"/>
        </div>
    )
}
export default NotFound
