import {
    DesktopOutlined,
    PieChartOutlined,
    UserOutlined,
    InfoCircleOutlined,
    UsergroupAddOutlined,
    AppstoreAddOutlined,
    UserSwitchOutlined

} from '@ant-design/icons'
function IconFinder (icon) {
    switch (icon) {
        case 'DesktopOutlined': return ( <DesktopOutlined /> )
        case 'PieChartOutlined': return ( <PieChartOutlined /> )
        case 'UserOutlined': return ( <UserOutlined /> )
        case 'InfoCircleOutlined': return ( <InfoCircleOutlined /> )
        case 'UsergroupAddOutlined': return ( <UsergroupAddOutlined /> )
        case 'AppstoreAddOutlined': return ( <AppstoreAddOutlined /> )
        case 'UserSwitchOutlined': return ( <UserSwitchOutlined />)
        default: break
    }
}
export default IconFinder
