import { questionActionTypes } from '@/redux/actions/questionActions/questionActionTypes'
const initialQuestionState = {
  questions: [],
  qsQueue: null
}

const questionReducer = (state = initialQuestionState, action) => {
  const { payload } = action
  // console.log(payload)
  switch (action.type) {
    case 'SET_QUESTIONS':
      return {
        ...state,
        questions: payload,
      }
    case 'SET_QUEUE':
      return {
        ...state, 
        qsQueue: payload
      }
    case questionActionTypes.ADD_QUESTION:
      return {
        ...state,
        questions: [...state.questions, payload],
      }
    case questionActionTypes.REMOVE_QUESTION:
      return {
        ...state,
        questions: state.questions.filter(
          (question) => question.id !== payload.id
        ),
      }
    case questionActionTypes.UPDATE_QUESTION:
      return {
        ...state,
        questions: state.questions.map((question) =>
          question.id === payload.id ? payload : question
        ),
      }
    case questionActionTypes.CLEAR_QUESTION:
      return {
        ...state,
        questions: [],
      }
    default:
      return state
  }
}

export default questionReducer
