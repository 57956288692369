import React, { useState } from "react";
import {Menu} from 'antd';
import basic from "@/constants/basic";
import routes from "@/constants/router";
import guard from "@/utils/permissions";
import IconFinder from "@/constants/icons";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'
import { Link, Redirect, NavLink, useParams  } from "react-router-dom";
import {useSelector} from 'react-redux'

import './menu.less'
const CONST_ROLE_ID = 'bb3e407d-f35d-4295-a21d-17173d17ff06'
const { SubMenu } = Menu
function MainMenu(props) {
    // const menu_key = useSelector((state) => state.basics.menu_key)
    const { t, i18n } = useTranslation()
    const location = useLocation()
    const params = new URLSearchParams()
    const user = useSelector(el => el?.auth?.user_info ? JSON.parse(el.auth.user_info) : {})

    const [selectedMenu, setSelectedMenu] = useState('/home')

    function getNumber (e) {
        if (e === '/') {
            return '/home'
        }
        return '/home' + e
    }
    // console.log(routes[0].children)
    function menuGenerator (routes) {
        if (routes) {
            return routes.filter(e => guard(e.meta.permission) && !e.hidden).map((e, i) => {
                if (e.children && e.children.length && e.isSub) {
                    return (
                        <SubMenu key={routes[0].path + e.path} icon={IconFinder(e.meta.icon)} title={e.meta.title}>
                            {
                                menuGenerator(e.children)
                            }
                        </SubMenu>
                    )
                } else {
                    return (
                        <Menu.Item key={getNumber(e.path)} icon={IconFinder(e.meta.icon)}>
                            <NavLink to={'/home' + e.path}>{ t(e.meta.title) }</NavLink>
                        </Menu.Item>
                    )
                }
            })
        }
    }
    const routesing = routes[0].children.filter(el => {
        if (el.name === 'users') {
            return user.user_role_id === CONST_ROLE_ID
        }
        return true
    })
    // console.log(routesing)
    return (
        <Menu theme={basic.MENU_THEME} onClick={(e) => setSelectedMenu(prev => e.path)} defaultSelectedKeys={[location.pathname]} mode='inline'>
            { menuGenerator(routesing) }
        </Menu>
    )
}

export default MainMenu
