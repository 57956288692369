import { lazy } from 'react'

import MainLayout from '@/layouts/MainLayout/MainLayout.jsx'
import ConstructorLayout from '@/layouts/ConstructorLayout/index.jsx'

const routes = [
  // Sidebar menu list
  {
    path: '/home',
    exact: true,
    name: 'home',
    component: MainLayout,
    meta: { title: 'home', icon: 'PieChartOutlined', permission: 'home' },
    children: [
      {
        path: '/projects',
        name: 'projects',
        component: lazy(() => import('../pages/Projects/Projects/index.jsx')),
        meta: {
          title: 'Мои проекты',
          icon: 'PieChartOutlined',
          permission: 'home',
        },
        children: [],
      },
      // {
      //   path: '/handbooks',
      //   name: 'handbooks',
      //   component: lazy(() => import('../pages/Handbook/index.jsx')),
      //   meta: { title: 'Справочники', icon: 'InfoCircleOutlined', permission: 'home' },
      //   children: []
      // },
      // {
      //   path: '/categories',
      //   name: 'categories',
      //   component: lazy(() => import('../pages/Category/index.jsx')),
      //   meta: { title: 'Категории', icon: 'AppstoreAddOutlined', permission: 'home' },
      //   children: []
      // },
      // {
      //   path: '/users',
      //   name: 'users',
      //   component: lazy(() => import('../pages/Users/index.jsx')),
      //   meta: { title: 'Пользователи', icon: 'UsergroupAddOutlined', permission: 'home' },
      //   children: []
      // },
      // {
      //   path: '/panel-list',
      //   name: 'panel-list',
      //   component: lazy(() => import('../pages/Panelist/index.jsx')),
      //   meta: { title: 'Панелисты', icon: 'InfoCircleOutlined', permission: 'home' },
      //   children: []
      // }
    ],
  },
  {
    path: '/users/:id',
    name: '/info',
    component: MainLayout,
    meta: {
      title: 'USer name',
      icon: 'InfoCircleOutlined',
      permission: 'home',
    },
    children: [
      {
        path: '/info',
        name: 'get-one-user',
        component: lazy(() => import('../pages/Users/UserInfo/index.jsx')),
        meta: {
          title: 'USer name',
          icon: 'InfoCircleOutlined',
          permission: 'home',
        },
      },
    ],
  },
  {
    path: '/panel-list/:id',
    name: '/panel-list',
    component: MainLayout,
    meta: { title: 'Start', icon: 'InfoCircleOutlined', permission: 'home' },
    children: [
      {
        path: '/user',
        name: 'panel-list-form',
        component: lazy(() => import('../pages/Panelist/userInfo.jsx')),
        meta: {
          title: 'Create survey',
          icon: 'InfoCircleOutlined',
          permission: 'home',
        },
      },
    ],
  },
  {
    path: '/profile',
    name: 'create-survey',
    component: MainLayout,
    meta: {
      title: 'Profile info',
      icon: 'InfoCircleOutlined',
      permission: 'home',
    },
    children: [
      {
        path: '/info',
        name: 'info-form',
        component: lazy(() => import('../pages/Profile/index.jsx')),
        meta: {
          title: 'Profile info',
          icon: 'InfoCircleOutlined',
          permission: 'home',
        },
      },
    ],
  },
  {
    path: '/projects/:id',
    name: 'create-survey',
    component: MainLayout,
    meta: {
      title: 'Справочники',
      icon: 'InfoCircleOutlined',
      permission: 'home',
    },
    children: [
      {
        path: '/create-survey',
        name: 'create-survey-form',
        component: lazy(() => import('../pages/Projects/Survey/index.jsx')),
        meta: {
          title: 'Create survey',
          icon: 'InfoCircleOutlined',
          permission: 'home',
        },
      },
    ],
  },

  {
    path: '/user-list',
    name: 'user-one',
    component: MainLayout,
    meta: {
      title: 'Пользователи',
      icon: 'InfoCircleOutlined',
      permission: 'home',
    },
    children: [
      {
        path: '/user',
        name: 'create-user-form',
        component: lazy(() => import('../pages/Users/Form/index.jsx')),
        meta: {
          title: 'user create',
          icon: 'InfoCircleOutlined',
          permission: 'home',
        },
      },
      {
        path: '/:id/user',
        name: 'edit-user-form',
        component: lazy(() => import('../pages/Users/Form/index.jsx')),
        meta: {
          title: 'Edit user',
          icon: 'InfoCircleOutlined',
          permission: 'home',
        },
      },
    ],
  },
  {
    path: '/survey',
    name: 'handbooks-list',
    component: MainLayout,
    meta: {
      title: 'handbooks-list',
      icon: 'PieChartOutlined',
      permission: 'home',
    },
    children: [
      {
        path: '/:id/list',
        name: 'survey-list',
        component: lazy(() => import('@/pages/Projects/Survey/index.jsx')),
        meta: {
          title: 'handbook details',
          icon: 'PieChartOutlined',
          permission: 'home',
        },
        children: [],
      },
    ],
  },
  {
    path: '/handbooks-list',
    name: 'handbooks-list',
    component: MainLayout,
    meta: {
      title: 'handbooks-list',
      icon: 'PieChartOutlined',
      permission: 'home',
    },
    children: [
      {
        path: '/:id/details',
        name: 'handbook-detailss',
        component: lazy(() => import('@/pages/Handbook/Details/index.jsx')),
        meta: {
          title: 'handbook details',
          icon: 'PieChartOutlined',
          permission: 'home',
        },
        children: [],
      },
    ],
  },
  {
    path: '/categories-list',
    name: 'categories-list',
    component: MainLayout,
    meta: {
      title: 'categories-list',
      icon: 'PieChartOutlined',
      permission: 'home',
    },
    children: [
      {
        path: '/create',
        name: 'category-create',
        component: lazy(() => import('@/pages/Category/Details/Create.jsx')),
        meta: {
          title: 'category create',
          icon: 'PieChartOutlined',
          permission: 'home',
        },
        children: [],
      },
      {
        path: '/:id/details',
        name: 'category-detailss',
        component: lazy(() => import('@/pages/Category/Details/Create.jsx')),
        meta: {
          title: 'category details',
          icon: 'PieChartOutlined',
          permission: 'home',
        },
        children: [],
      },
    ],
  },
  {
    path: '/construct/editor',
    name: 'editor-constructor',
    component: ConstructorLayout,
    meta: { title: 'Editor', icon: 'PieChartOutlined', permission: 'home' },
    children: [
      {
        path: '/:project_id/:id/:company_id/:inquery_id/:count',
        name: 'anketa',
        component: lazy(() =>
          import('@/pages/ConstructorPanel/questionnaire/index.jsx')
        ),
        meta: {
          title: 'New Constructor',
          icon: 'PieChartOutlined',
          permission: 'home',
        },
        children: [],
      },
      {
        path: '/:project_id/:id/settings',
        name: 'anketa-editor',
        component: lazy(() =>
          import('@/pages/ConstructorPanel/settings/index.jsx')
        ),
        meta: {
          title: 'Edit constructor',
          icon: 'PieChartOutlined',
          permission: 'home',
        },
        children: [],
      },
      {
        path: '/:project_id/:id/collect-reponses',
        name: 'collect-reponses',
        component: lazy(() =>
          import('@/pages/ConstructorPanel/responses/index.jsx')
        ),
        meta: {
          title: 'Collect responses',
          icon: 'PieChartOutlined',
          permission: 'home',
        },
        children: [],
      },
      {
        path: '/:project_id/:id/results',
        name: 'anketa-results',
        component: lazy(() =>
          import('@/pages/ConstructorPanel/results/index.jsx')
        ),
        meta: {
          title: 'Results',
          icon: 'PieChartOutlined',
          permission: 'home',
        },
        children: [
          {
            path: '/analysis-results',
            name: 'anketa-analysis-results',
            component: lazy(() =>
              import('@/pages/ConstructorPanel/results/analysis/index.jsx')
            ),
            meta: {
              title: 'analysis-results',
              icon: 'PieChartOutlined',
              permission: 'home',
            },
          },
        ],
      },
    ],
  },
]

export default routes
