import { conditionActionTypes } from '../actions/conditionActions/conditionActionTypes'

const initialConditionState = {
  conditions: [],
}

const conditionReducer = (state = initialConditionState, action) => {
  const { payload } = action
  switch (action.type) {
    case 'SET_CONDITIONS':
      return {
        ...state,
        conditions: payload,
      }
    case conditionActionTypes.ADD_CONDITION:
      return {
        ...state,
        conditions: [...state.conditions, payload],
      }
    case conditionActionTypes.REMOVE_CONDITION:
      return {
        ...state,
        conditions: state.conditions.filter(
          (condition) => condition.id !== payload.id
        ),
      }
    case conditionActionTypes.UPDATE_CONDITION:
      return {
        ...state,
        conditions: state.conditions.map((condition) =>
          condition.id === payload.id ? payload : condition
        ),
      }
    case conditionActionTypes.CLEAR_CONDITION:
      return {
        ...state,
        conditions: [],
      }
    default:
      return state
  }
}

export default conditionReducer
