import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import authReducer from './authReducer'
import systemReducer from './systemReducer'
import basicReducer from './basicReducer'
import crudReducer from './crudReducer'
import { persistReducer } from 'redux-persist'
import nodeReducer from './nodeReducer'
import questionReducer from './questionReducer'
import relationReducer from './relationReducer'
import actionReducer from './actionReducer'
import drawerReducer from './drawerReducer'
import conditionReducer from './conditionReducer'
import stackReducer from './stackReducer'
// import infoReducer from './infoReducer'

const nodesPersistConfig = {
  key: 'nodes',
  storage,
  whitelist: ['elements'],
}
const questionPersistConfig = {
  key: 'questions',
  storage,
  whitelist: ['questions'],
}
const qsPersistConfig = {
  key: 'qsQueue',
  storage,
  whitelist: ['qsQueue'],
}
const stack = {
  key: 'stack',
  storage,
  whitelist: ['stack'],
}
const actionPersistConfig = {
  key: 'actions',
  storage,
  whitelist: ['actions'],
}
const relationPersistConfig = {
  key: 'relations',
  storage,
  whitelist: ['relations'],
}
const conditionPersistConfig = {
  key: 'conditions',
  storage,
  whitelist: ['conditions'],
}

const rootReducer = combineReducers({
  auth: authReducer,
  system: systemReducer,
  basics: basicReducer,
  crud: crudReducer,
  nodeElements: persistReducer(nodesPersistConfig, nodeReducer),
  questions: persistReducer(questionPersistConfig, questionReducer),
  qsQueue: persistReducer(qsPersistConfig, questionReducer),
  stack: persistReducer(stack, stackReducer),
  actions: persistReducer(actionPersistConfig, actionReducer),
  relations: persistReducer(relationPersistConfig, relationReducer),
  conditions: persistReducer(conditionPersistConfig, conditionReducer),
  drawer: drawerReducer,
})

export default rootReducer
